@font-face {
    font-family: "Archivo-Black";
    src: local("Archivo-Black"), url(../../font/ArchivoBlack-Regular.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.art3Landing {
    overflow: inherit;
    font-family: Epilogue;

}
.issue3_background{
    background-image:url('../../img/Issue3/issue3_landing_background.png');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0%;
    opacity: 1;
    max-width: 100%;
    height:120vh;
}

.issue3_landing{
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    left: 15%;
    width: 85%;
    color: white;
    border-color: white;
    border-style: solid;
    background-color: black;
    border-radius: 2px;
    text-align: justify;
    padding: 1rem 2rem;
}
.issue3_landing > h7{
    font-family: Archivo-Black;
}
.issue3_landing >h1{
    font-family: Archivo-Black;
}
.issue3_landing > p{
    font-family: Epilogue;
    font-size: 1rem;
}
.record_player{
    position: relative;
    left: 15%;
    width: 85%;
    cursor: pointer;
}
.issue3_landing1{
    position: relative;
    left: 25%;
    top: 5%;
    width: 55%;
}

.issue3_landing2{
    position: relative;
    left: 25%;
    top: 20%;
    width: 55%;
}

.issue3_landing3{
    position: relative;
    top: 10%;
    left: 20%;
    width: 55%;
}
.issue3_landing4{
    position: relative;
    left: 20%;
    width: 55%;
    top:25%;
}
.carousel-control-next-icon {
    background-image: none !important;
}
.carousel-control-prev-icon {
    background-image: none !important;
}

@media (max-width:767px) {
    .landingCarousel li {
        border-radius:50%;
        height:10px;
        width:10px;
    }
    .carousel-indicators {
        top: 97%;
    }
    .carousel-item > h1 {
        color: #ffffff;
        font-size:1rem;
        text-align:center;
        font-family: Archivo-Black;
    }
    .mobileChoose {
        color: #76B19F;
        font-size:1rem;
        text-align:center;
        font-family: Archivo-Black;
        margin:auto;
    }
    .issueBox {
        padding-left:2rem;
        padding-right:2rem;
    }
    .issue3_landing{
        left: 0%;
        width: 100%;
        padding: 1rem 1rem;
        padding-bottom: 0rem;
    }
    .record_player{
        left: 0%;
        width:95%;
        max-width:100%;
        padding: 1rem 1rem;
    }
    .issue3_landing1{
        width: 95%;
        left:0%;
        top:0%;
        padding: 1rem 1rem;
    }
    .issue3_landing2{
        width: 95%;
        left:0%;
        top:0%;
        padding: 1rem 1rem;
    }
    .issue3_landing3{
        width: 95%;
        left:0%;
        top:0%;
        padding: 1rem 1rem;
    }
    .issue3_landing4{
        width: 95%;
        left:0%;
        top:0%;
        padding: 1rem 1rem;
    }
}