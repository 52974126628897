@font-face {
    font-family: "GT-Haptik-Black-Trail";
    src: local("GT-Haptik-Black-Trail"), url(../../../font/GT-Haptik-Black-Trial.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face{
    font-family: "GT-Walsheim-Pro-Regular-Trial";
    src: local("GT-Walsheim-Pro-Regular-Trial"), url(../../../font/GT-Walsheim-Pro-Regular-Trial.otf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tse_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    font-size: 1.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: #257a66;
}

.tse_div{
    display: flex;
    /* justify-content: center; */
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    /* margin-left: 0.2rem; */
    width: 100% !important;
}

.tse_div:nth-child(1)>div{
    width: 12rem;
    margin-left: 1rem;
    margin-top: 1rem;
}
.tse_div>img{
    max-width: 35%;
    margin: 0;
    flex:auto;
}

@media (max-width:767px){
    .tse_text{
        font-size: 1rem;
    }
    .tse_div{
        max-width: 100%;
    }
    .tse_row{
        justify-content: center;
    }
}