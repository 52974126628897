.eosticker1{
    position: relative !important;
    max-width: 100%;
    margin-top: -1rem;
}

.eosticker2{
    position: relative !important;
    max-width: 100%;
}

.eosticker3{
    position: relative !important;
    max-width: 100%;
}

@media (max-width:767px) {
    .eosticker1{
        margin-top: 2rem;
        max-width: 150%;
    }
    .eosticker2{
        max-width: 120%;
        top: 40%;
    }

    .eosticker3{
        max-width: 150%;
        top:40%;
    }

}