.article_question{
    font-family: Corben !important;
    color: black;
    background: white !important;
}

.articleFont {
    font-family: Epilogue;
    overflow: hidden;
}
.heart1{
    width: 100%;
    opacity: .3;
}
.heart2{
    width: 100%;
    opacity: .5;
}
.heart3{
    width: 100%;
    opacity: .7;
}
.heart4{
    width: 100%;
}

.hoverText{
    color:#76B19F !important;
}
.disc1 {
    max-width:100%;
}
.testerDisc {
    max-width:75%;
    position:absolute;
}
.tester2 {
    background-image:url('../../../img/Issue3/flowerpot.png');
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 20%;
    opacity: 0.175;
    max-width: 100%;
}
.flower_background {
    background-size: cover;
    position: absolute;
    top: 10%;
    right: 0px;
    left: 10%;
    z-index: 0;
    opacity: 0.175;
    width: 35%;
}

.star_background1{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    top:0%;
    right: 20%;
}
.star_background2{
    position: absolute;
    max-width: 75%;
    z-index: 2;
    top:30%;
    right: 40%;
}
.star_background3{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    right: 10%;
    top:70%;
    /* right: 0px; */
}
.star_background4{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    right: 30%;
    top:-5%;
    /* right: 0px; */
}
.star_background5{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    right: 0%;
    top:5%;
    /* right: 0px; */
}
.star_background6{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    right: 10%;
    top:40%;
    /* right: 0px; */
}
.star_background7{
    position: absolute;
    max-width: 75%;
    opacity: 0.175;
    z-index: 1;
    right: -10%;
    top:70%;
    /* right: 0px; */
}

.rectangle_frame{
    position: absolute;
    top: 50%;
    bottom: 0px;
    right: 10%;
    width: 100%;
}
.circle_frame{
    position: absolute;
    top: -90%;
    /* bottom: 0px; */
    left: -10%;
    width: 50%;
}
.star_frame{
    position: absolute;
    display: flex;
    top: -80%;
    /* bottom: 0px; */
    right: -35%;
    width: 50%;
}
.polygon_frame{
    position: absolute;
    top: 45%;
    bottom: 0px;
    right: 10%;
    width: 50%;
}
.designCircle {
    border: 2px solid #76B19F;
    box-sizing: border-box;
    border-radius:40px;
    padding-left:0.5rem;
    padding-right:0.5rem;
}
.ethicalQuestion {
    margin-bottom:4rem;
}
.newQuestion {
    margin-top:4rem;
    margin-bottom:2rem;
}
.beginQuestion {
    margin-bottom:2rem;
}
.endBlock {
    margin-bottom:2rem;
}


.glove {
    bottom:0;
    position:absolute;
}
.sparkle {
    margin-left:-25%;
    position:absolute;
}
@media (min-width:768px) {
    .flower_background {
        height: 80vh;
    }
    .tinyImageBox {
        max-width:5%;
    }
    .articleText > p {
        font-size: 1.25rem;
    }
    .articleText {
        font-size: 1.25rem;
    }
    .captionText {
        font-size: 1rem;
    }
    .captionText > a {
        font-size: 1rem;
    }
    .backgroundImageBox {
        max-width:100%;
        opacity:0.25;
    }
    .article_question1 {
        font-size: 1.35rem !important;
        font-weight: 900;
        padding: 10px;
        box-decoration-break: clone;
        display: inline;
        box-shadow: 0.25em 0 0 #EBD448, -0.25em 0 0 #EBD448;
        background-color: #EBD448;
        line-height: 2.25;
        background-color: #EBD448;
        color: #5F53E5;
    }
    .article_question2 {
        font-size: 1.35rem !important;
        background-color: #EBD448;
        max-width: 80%;
        padding: 10px;
        color: #5F53E5;
    }
    .article_question3 {
        font-size: 1.35rem !important;
        padding: 10px;
        box-decoration-break: clone;
        display: inline;
        box-shadow: 0.25em 0 0 #EBD448, -0.25em 0 0 #EBD448;
        background-color: #EBD448;
        line-height: 2.25;
        color: #5F53E5;
    }

    .backToTopWrapper {
        visibility: hidden;
        width:0;
        height:0;
        display:block;
    }
}
@media (max-width:767px) {
    .flower_background {
        height:50vh;
        top: 15%;
    }
    .article_question1 {
        font-size: .75rem !important;
        font-weight: 900;
        padding: 10px;
        box-decoration-break: clone;
        display: inline;
        box-shadow: 0.25em 0 0 #EBD448, -0.25em 0 0 #EBD448;
        background-color: #EBD448;
        line-height: 3;
        background-color: #EBD448;
        color: #5F53E5;
    }
    .article_question2 {
        font-size: .75rem !important;
        background-color: #EBD448;
        max-width: 80%;
        padding: 10px;
        color: #5F53E5;
    }
    .article_question3 {
        font-size: .75rem !important;
        padding: 10px;
        box-decoration-break: clone;
        display: inline;
        box-shadow: 0.25em 0 0 #EBD448, -0.25em 0 0 #EBD448;
        background-color: #EBD448;
        line-height: 3;
        color: #5F53E5;
    }
    .rectangle_frame{
        position: absolute;
        top: 10% !important;
        /* bottom: 0px; */
        right: 80%;
        /* display: none; */
        width: 50%;
    }
    .circle_frame{
        position: absolute;
        top: 90%;
        /* bottom: 0px; */
        left: -10%;
        width: 50%;
    }
    .star_frame{
        position: absolute;
        display: flex;
        top: -80%;
        display: none;
        /* bottom: 0px; */
        right: -35%;
        width: 50%;
    }
    .polygon_frame{
        position: absolute;
        top: 45%;
        bottom: 0px;
        right: 10%;
        width: 50%;
    }
    .star_background1{
        display: none;
    }
    .star_background2{
        display: none;
    }
    .star_background3{
        display: none;
    }
    .star_background4{
        position: absolute;
        max-width: 75%;
        opacity: 0.175;
        z-index: 1;
        right: 30%;
        top:-5%;
        /* right: 0px; */
    }
    .star_background5{
        /* display: none; */
        top:20%;
    }
    .flower_background{
        width: 100%;
    }
    .mobileHidden{
        visibility: hidden;
    }
    .backToTop {
        position: fixed;
        bottom:0;
        right:0;
    }
    .backToTopWrapper {
        position:absolute;
        z-index:999;
    }
    .tinyImageBox {
        max-width:15%;
    }
    .mobileImage {
        max-width:100% !important;
    }
    .mobilePadding {
        padding-left:1.5rem;
        padding-right:1.5rem
    }
    .articleHeading {
        height: 20vh;
    }
    .articleText > p {
        font-size: .75rem;
    }
    .articleText {
        font-size: .75rem;
    }
    .captionText {
        font-size: .75rem;
    }
    .captionText > a {
        font-size: .75rem;
    }
    .backgroundImageBox {
        max-width:25%;
        opacity:.25;
    }
    .articleQuestion {
        font-size: 1.35rem !important;
    }
}
.introduction_1 {
    background: #034BB5;
    padding-top:4rem;
    color: #ffffff; 
}
.introduction_2 {
    background: white;
    padding-bottom:4rem;
    color: #ffffff; 
}
.second_section {
    background: #FFFAF4;
    padding-top:4rem;
    padding-bottom:4rem;
}
.third_section {
    background: #034BB5;
    padding-top:4rem;
    padding-bottom:4rem;
    color:#ffffff;
}
.fourth_section {
    background: #FFFAF4;
    padding-top:4rem;
    padding-bottom:4rem;
}
.fifth_section {
    background: #034BB5;
    padding-top:4rem;
    padding-bottom:4rem;
    color: white;
    overflow: hidden;
}
.sixth_section {
    background: #FFFAF4;
    padding-top:4rem;
    padding-bottom:4rem;
}
:root {
    /* Temp Variable for testing, fix in final*/
    --record-size: 8vw;
    --record-holder: 9vw;
    --record-holder-half-neg: -4.5vw;
    --disk-margins: 0.5vw;
    /* Temp found from 91 offset from top/1280 page height (dimensions used in figma)*/
    --loading-offset-top: 7.109vh;
    /* Variables to match bootstrap columns for fixed elements */
    --column-length: 8.33vw;
    /* Above not working for loading bar margin, guess and check here */
    --column-length-test: 7.33vw;
  }
body {
    color: #000000;
}

.articleHeading {
    padding: 0px;
}
.articleHeading > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56.25px;
    color: #000000;
    /* Matching design doc (probably should swap to percentages later)*/
    margin-top: 69px;
}

/* Testing column bounds*/
.columnLeft  {
    height:100%;
}
.columnLeft > .loadingDiskBase {
    left: 0;
    bottom: 0;
}
/* Testing column bounds*/
.columnRight {
    height:100%;
}

/*Temp class I added could not find design guidance atm (3/6/21)*/
.articleText > p {
    font-family: Epilogue;
    font-style: normal;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: justify;
    z-index:1;
}
.articleText {
    font-family: Epilogue;
    font-style: normal;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: justify;
}

.captionText {
    font-family: Epilogue;
    font-style: normal;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: justify;
    display:flex;
    justify-content: center;
}
.captionText > a {
    font-family: Epilogue;
    font-style: normal;
    font-weight: normal;
    line-height: 2.5rem;
    z-index:1;
    text-align: justify;
}

.imageBox{
    max-width:100%;
}
.smallImageBox{
    max-width:100% !important;
}
.noPad {
    padding-left:0px !important;
    padding-right:0px !important;
}


.stickyZone {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0px;
    height: 0px;
    visibility:hidden;
  }

.loadingArea {
    /*Temp dimensions, replace in final*/
    width: var(--record-holder);
    height: var(--record-holder);
    position: absolute;
    top: var(--loading-offset-top);

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    left: var(--column-length-test);
    padding:0px;
    /* Transform used to make the scrolling happen
     in the direction of the figma 
     sketch titled Article_Web_End*/
    transform: rotate(270deg) scaleY(-1);
}
