@font-face {
    font-family: "GT-Haptik-Black-Trail";
    src: local("GT-Haptik-Black-Trail"), url(../../../font/GT-Haptik-Black-Trial.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face{
    font-family: "GT-Walsheim-Pro-Regular-Trial";
    src: local("GT-Walsheim-Pro-Regular-Trial"), url(../../../font/GT-Walsheim-Pro-Regular-Trial.otf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.acm_row{
    background-color: #ce5cf6;
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    z-index: 1;
    width:100%;
    margin: 0 !important;
    justify-content:center;
}

.acm_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    margin-left: 0rem;
    font-size: 1.1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #d35135;
    border-style: solid;
    border-width: 2px;
    border-color: black;
}

.acm_div{
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    background-color: #257a66;
    border-style: solid;
    border-width: 2px;
    border-color: black;
}

.acm_div:nth-child(1)>div{
    width: 16rem;
    margin-left: 1rem;
    margin-top: 1rem;
}
.acm_div>img{
    max-width: 30%;
    flex:auto;
    margin: 0;
}

.acm_row2{
    position: relative;
    z-index: 1;
}

.acm_row3{
    position: relative;
    z-index: 1;
}
.alignEnd {
    display:flex;
    align-items: flex-end;
    padding: 0;
}
@media(max-width:767px){
    .acm_text{
        font-size: 1rem;
        margin:0rem;
    }
    .acm_row{
        top:0%;
    }
    .acm_row2{
        position: relative;
        top:0%;
    }
    
    .acm_row3{
        position: relative;
        top:0%;
    }
    .acm_div:nth-child(1)>div>a{
        width: 85%;
        margin-left: 0rem;
        margin-bottom: 1rem;
    }
}