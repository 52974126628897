@font-face {
    font-family: "GT-Haptik-Black-Trail";
    src: local("GT-Haptik-Black-Trail"), url(../../../font/GT-Haptik-Black-Trial.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face{
    font-family: "GT-Walsheim-Pro-Regular-Trial";
    src: local("GT-Walsheim-Pro-Regular-Trial"), url(../../../font/GT-Walsheim-Pro-Regular-Trial.otf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.trend_img{
    border-style: solid;
    border-width: 2px;
    border-color: black;
    max-width: 100%;
    max-height: 95%;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    z-index: 1;
    flex:auto;
    background-color: #e08f61;
}

.trend_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    font-size: 1.1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color:#257a66;
}
.trend_div{
    border-style: solid;
    border-width: 2px;
    border-color: black;
    background-color: #e08f61;
    display: flex;
    justify-content: flex-start;
    width:100%;
}
.trend_div>img{
    max-width: 30%;
    flex:auto;
}

.trend_div_div>a{
    margin-left: 1rem;
    width: 125%;
}
.trend_mobile{
    display: none;
}

@media (max-width:767px){
    .trend_img{
        display: none;
    }
    .trend_mobile{
        display: flex;
        /* flex:auto; */
    }
    .trend_img1{
        max-width: 100%;
        border-style: solid;
        border-width: 2px;
        border-color: black;
        max-width: 100%;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        padding-left: 3rem;
        padding-right: 3rem;
        background-color: #e08f61;
    }
   
    .trend_text{
        font-size: 1rem;
    }
    .trend_row{
        justify-content: center;
    }
}