@font-face {
    font-family: "GT-Haptik-Black-Trail";
    src: local("GT-Haptik-Black-Trail"), url(../../../font/GT-Haptik-Black-Trial.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face{
    font-family: "GT-Walsheim-Pro-Regular-Trial";
    src: local("GT-Walsheim-Pro-Regular-Trial"), url(../../../font/GT-Walsheim-Pro-Regular-Trial.otf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.arch_row>a{
    margin-left: 0.5rem;
}

.arch_row{
    border-style: solid;
    border-width: 2px;
    border-color: black;
}

.arch_row2{
    position: relative;
    top:7%;
    /* justify-content: end; */
    /* margin:0; */
    z-index: 1;
}

.arch_row3{
    /* margin:0; */
    margin-left:-15px;
    position: relative;
    top:7%;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    border-style: solid;
    border-top: none;
    border-width: 2px;
    border-color: black;
    z-index: 1;
}

.arch_img{
    position: relative;
    left:65%;
    max-width: 35%;
    flex:auto;
}
.arch_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    font-size: 1.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #d35135;
    /* justify-content: flex-end; */
    /* display: flex; */
    /* align-items: flex-end; */
}

@media (max-width:767px){
    .arch_row>a{
        margin-left: 2rem;
    }
    
    .arch_row{
        border-style: solid;
        border-width: 2px;
        border-color: black;
    }
    
    .arch_row2{
        position: relative;
        top:0%;
    }
    
    .arch_row3{
        top:-1%;
    }
    
    .arch_img{
        left:0%;
        max-width: 100%;
    }
    .arch_text{
        font-size: 1rem;
    }
}