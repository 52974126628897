.yps_row{
    border-style: solid;
    border-width: 2px;
    border-color: black;
    background-color:#ce5cf6;
    position: relative;
    z-index: 1;
    margin:0;
}
.yps_row>a{
    margin-left: 0.5rem;
}

.yps_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    font-size: 1.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #d35135;
    border-style: solid;
    border-width: 2px;
    border-color: black;
}

.yps_row2{
    position: relative;
    top:10%;
    z-index: 1;
}
.yps_row3{
    position: relative;
    top:10%;
    z-index: 1;
}

@media (max-width:767px){
    .yps_row2>img{
        /* flex:auto; */
        max-width: 100% !important;
    }
    .yps_text{
        font-size: 1rem;
    }
    .yps_row>a{
        margin-left: 2rem;
    }
    .yps_row{
        top:0%;
    }
    .yps_row2{
        top:0%;
    }
    .yps_row3{
        top: 0%;
    }
}