:root {
    /* Temp Variable for testing, fix in final*/
    --record-size: 264px;
    --record-holderTotal: 15vw;
    --record-holder: 9vw;
    --record-holder-half-neg: -4.5vw;
    --disk-margins: 0.5vw;
    /* Temp found from 91 offset from top/1280 page height (dimensions used in figma)*/
    --loading-offset-top: 7.109vh;
    /* Variables to match bootstrap columns for fixed elements */
    --column-length: 8.33vw;
    /* Above not working for loading bar margin, guess and check here */
    --column-length-test: 7.33vw;
  }
body {
    color: #000000;
}

.articleHeading {
    padding: 0px;
}
.articleHeading > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56.25px;
    color: #000000;
    /* Matching design doc (probably should swap to percentages later)*/
    margin-top: 69px;
}

/* Testing column bounds*/
.columnLeft  {
    height:100%;
}
.columnLeft > .loadingDiskBase {
    left: 0;
    bottom: 0;
}
/* Testing column bounds*/
.columnRight {
    height:100%;
}

/*Temp class I added could not find design guidance atm (3/6/21)*/
.articleText > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
}

/*Class for disks appearing in left column*/
.linkDisk {
    border-radius: 50%;
    /*Random Height Val, replace in final design*/
    height: var(--record-size);
    width: var(--record-size);
    flex-shrink: 0px;
    margin-bottom: -3.8vw;
    padding:0px;
    z-index:0;
}

/*Class for disks appearing in left column*/
.linkDiskSecondary {
    border-radius: 50%;
    /*Random Height Val, replace in final design*/
    height: var(--record-size);
    width: var(--record-size);
    flex-shrink: 0px;
    margin-bottom: -6.2vw;
    padding:0px;
    z-index:-1;
    text-align: center;
}

/*Class for record/disk holder at bottom of column*/
.linkDiskBase {
   /*Temp dimensions, replace in final*/
    width: var(--record-holder);
    /*height: 4vh;*/
    z-index: 1;
    margin-bottom: 0px;
    text-align: center;
}

.imageBox{
    max-width:100%;

}

.diskArea {
    /*Temp dimensions, replace in final*/
    width: var(--record-holderTotal);
    height: 8vw;
    position: fixed;
    bottom:0;
    z-index: 2;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    left: var(--column-length);
}


.diskArea:hover {
    /*Temp dimensions (4*record--size), replace in final*/
    height: 32vw;
}


.diskArea:hover > .linkDisk {
    margin-bottom: var(--disk-margins);
}

.diskArea:hover > .linkDiskSecondary {
    margin-bottom: var(--disk-margins);
}

.stickyZone {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0px;
    height: 0px;
  }

.loadingArea {
    /*Temp dimensions, replace in final*/
    width: var(--record-holder);
    height: var(--record-holder);
    position: absolute;
    top: var(--loading-offset-top);

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    left: var(--column-length-test);
    padding:0px;
    /* Transform used to make the scrolling happen
     in the direction of the figma 
     sketch titled Article_Web_End*/
    transform: rotate(270deg) scaleY(-1);
}
