.alsticker1{
    max-width: 15%;
    top: -10%;
    left:0;
}

.alsticker2{
    position: relative !important;
    max-width: 25%;
    left: 80%;
    margin-top: -3rem;
}

.alsticker3{
    max-width: 25%;
    right:0;
    bottom: 15%;
}

.alsticker4{
    position: relative !important;
    max-width: 100%;
    margin-top: -1rem;
}
.alpic1{
    max-width: 100%;
    margin-top: 2rem;
}
.alpic2{
    max-width: 100%;
}

@media (max-width:767px) {
    .alsticker1{
        top:-5%;
        left:5%;
    }
    .alsticker2{
        position: absolute !important;
        margin-top: 0rem;
        bottom:10%;
        left:-20%;
    }
    .alsticker3{
        bottom: 30%;
    }
    .alsticker4{
        max-width: 120%;
        margin-top: 2rem;
    }
    .alpic1{
        max-width: 125%;
        margin-top: 0rem;
    }
    .alpic2{
        max-width: 105%;
        margin-top: 4rem;
    }
}