.articleFont {
    font-family: Corben;
}
.corbenBold {
    font-family: CorbenBold !important;
}
.designCircle {
    border: 2px solid #76B19F;
    box-sizing: border-box;
    border-radius:40px;
    padding-left:0.5rem;
    padding-right:0.5rem;
}
.sideArticle1 {
    max-width:100%;
    margin-right:5rem;
}
@media (min-width:768px) {
    .equitableQuestion {
        margin-bottom:-2rem;
    }
    .corbenBold {
        font-size: 1.125rem;
    }
    .articleText > p {
        font-size: 1.25rem;
    }
    .articleText {
        font-size: 1.25rem;
    }
    .headerText {
        font-size: 1.3rem;
    }
    .fleetlibrarycubicle {
        margin-top:-5rem;
    }
    .corktrailrail {
        margin-left:-5rem;
    }
}
@media (max-width:767px) {
    .equitableQuestion {
        margin-bottom:0rem;
    }
    .mobileImage {
        max-width:100% !important;
    }
    .mobilePadding {
        padding-left:1.5rem;
        padding-right:1.5rem
    }
    .articleHeading {
        height: 20vh;
    }
    .corbenBold {
        font-size: .5rem;
    }
    .articleText > p {
        font-size: .75rem;
    }
    .articleText {
        font-size: .75rem;
    }
    .headerText {
        font-size: 1.125rem;
    }
}
.equitableIntroduction {
    background: #D8D5C6;
    padding-top:4rem;
    padding-bottom:4rem;
}
.universalDesign {
    background: #FFFAF4;
    padding-top:4rem;
    padding-bottom:4rem;
}
.fleetlibrary {
    background: #E5E5E5;
    padding-top:4rem;
    padding-bottom:4rem;
}
.designingforall {
    background: #FFFAF4;
    padding-top:4rem;
    padding-bottom:8rem;
}
:root {
    /* Temp Variable for testing, fix in final*/
    --record-size: 8vw;
    --record-holder: 9vw;
    --record-holder-half-neg: -4.5vw;
    --disk-margins: 0.5vw;
    /* Temp found from 91 offset from top/1280 page height (dimensions used in figma)*/
    --loading-offset-top: 7.109vh;
    /* Variables to match bootstrap columns for fixed elements */
    --column-length: 8.33vw;
    /* Above not working for loading bar margin, guess and check here */
    --column-length-test: 7.33vw;
  }
body {
    color: #000000;
}

.articleHeading {
    padding: 0px;
}
.articleHeading > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56.25px;
    color: #000000;
    /* Matching design doc (probably should swap to percentages later)*/
    margin-top: 69px;
}

/* Testing column bounds*/
.columnLeft  {
    height:100%;
}
.columnLeft > .loadingDiskBase {
    left: 0;
    bottom: 0;
}
/* Testing column bounds*/
.columnRight {
    height:100%;
}

/*Temp class I added could not find design guidance atm (3/6/21)*/
.articleText > p {
    font-family: Corben;
    font-style: normal;
    font-weight: normal;
    line-height: 2rem;
    text-align: justify;
    z-index:1;

}
.articleText {
    font-family: Corben;
    font-style: normal;
    font-weight: normal;
    line-height: 2rem;
    text-align: justify;

}


.imageBox{
    max-width:100%;
}
.noPad {
    padding-left:0px !important;
    padding-right:0px !important;
}

.stickyZone {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0px;
    height: 0px;
    visibility:hidden;
  }

.loadingArea {
    /*Temp dimensions, replace in final*/
    width: var(--record-holder);
    height: var(--record-holder);
    position: absolute;
    top: var(--loading-offset-top);

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    left: var(--column-length-test);
    padding:0px;
    /* Transform used to make the scrolling happen
     in the direction of the figma 
     sketch titled Article_Web_End*/
    transform: rotate(270deg) scaleY(-1);
}
