.dfsd_img{
    max-width: 100%;
    background-color: #e08f61;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    z-index: 1;
    flex:auto;
}

.dfsd_row{
    background-color:#ce5cf6;
    /* position: relative; */
    /* top:60%; */
    margin: 0;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    z-index: 1;
}
.dfsd_row>a{
    /* margin-top: 1rem; */
    margin-left: 0.5rem;
}

.dfsd_row2{
    position: relative;
    top:15%;
}

.dfsd_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    font-size: 1.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #d35135;
}

.dfsd_mobile{
    display: none;
}
@media(max-width:767px){
    .dfsd_mobile{
        display: flex;
        justify-content: center;
        /* flex:auto; */
    }
    .dfsd_img{
        display:none;
    }
    .dfsd_img1{
        max-width: 100%;
        background-color: #e08f61;
        padding-left: 7rem;
        padding-right: 7rem;
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
        border-style: solid;
        border-width: 2px;
        border-color: black;
    }
    .dfsd_text{
        font-size: 1rem;
    }
    .dfsd_row{
        top:0%;
    }
    .dfsd_row2{
        top: 0%;
    }
    .dfsd_row>a{
        margin-left: 2rem;

    }

}