.community_container{
    padding-left: 10rem;
    padding-right: 10rem;
}
.community_design_font {
    font-family: Epilogue;
    font-size: 1rem;
    line-height: 2rem;
    color:white;
    text-align: justify;
}
.community_design_font1 {
    font-family: Epilogue;
    font-size: 1rem;
    line-height: 2rem;
    color:black;
    text-align: justify;
}
.black_background{
    background-color: black;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
}

.white_background{
    background-color: #FFFAF4;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.community_pic{
    max-width: 100%;
}
.community_pic1{
    max-width: 100%;
}
.community_cancer{
    max-width: 80%;
    margin-left: 2rem;
    margin-top: -2rem;
}
.community_cancer1{
    max-width: 80%;
    /* margin-left: 2rem; */
    margin-top: -2rem;
}
.trash{
    margin-top: -1rem;
}
.community_col{
    /* padding-right: 5rem; */
}
.community_bold{
    color: #76B19F;
    font-family: CorbenBold;
}
.community_bold1{
    color: #5E53D9;
    font-family: CorbenBold;
}
.community_paragraph{
    margin-bottom: 1rem;
}
.authors {
    color: #ffffff;
    display:flex;
    margin-bottom:1rem;
}
.authorsDark {
    color: black;
    display:flex;
    margin-bottom:1rem;
}
.authorsDark > p {
    color:black;
}

.corben {
    font-family: Corben;
}

.community_phone{
    width: 30%;
    position: relative;
    left: 30%;
}
.cancer{
    padding-top: 4rem;
    /* padding-right: 5rem; */
}

.community_star1{
    position: absolute;
    top:5%;
    left: 30%;
    width: 40%;
    transform: rotate(-30deg);
}
.community_star2{
    position: absolute;
    top:25%;
    left: 30%;
    width: 40%;
    opacity: 0.75;
    transform: rotate(-30deg);
}
.community_star3{
    position: absolute;
    top:45%;
    left: 30%;
    width: 40%;
    opacity: 0.5;
    transform: rotate(-30deg);
}
.community_star4{
    position: absolute;
    top:65%;
    left: 30%;
    width: 40%;
    opacity: 0.25;
    transform: rotate(-30deg);
}
.community_star5{
    position: absolute;
    top:85%;
    left: 30%;
    width: 40%;
    opacity: 0.1;
    transform: rotate(-30deg);
}

.center{
    text-align: center;
}

.disks{
    bottom: 10rem;
    max-width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media (min-width:768px) {
    .special {
        font-size: 1.375rem;
    }
    .specialInterview {
        font-size:1.375rem;
    }
    .lineSpace {
        line-height:24px;
    }
    .lineSpace2 {
        line-height:24px;
    }
    .authors {
        justify-content:end;
    }
    .authorsDark {
        justify-content:end;
    }
}
@media (max-width:767px) {
    .specialInterview {
        font-size: .9rem;
        padding-right:0rem !important;
        padding-left:0rem !important;
    }
    .lineSpace2 {
        line-height:24px;
    }
    .authors {
        justify-content:center;
    }
    .authorsDark {
        justify-content:center;
    }
    .lineSpace {
        line-height:28px;
    }
    .center{
        text-align: left;
    }
    .special{
        font-size: 1rem !important;
        padding-right:0rem !important;
        padding-left:0rem !important;
    }
    .community_design_font{
        font-size:.75rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .community_design_font1{
        padding-right: 1rem;
        padding-left: 1rem;
        font-size:.75rem;
    }
    .community_phone{
        left: 35%;
    }
    .community_star1{
        position: absolute;
        top:0%;
        left:85%;
        /* right:0%; */
        width: 30%;
        transform: rotate(-30deg);
    }
    .community_star2{
        position: absolute;
        top:200%;
        left:85%;
        width: 30%;
        opacity: 0.75;
        transform: rotate(-30deg);
    }
    .community_star3{
        position: absolute;
        top:400%;
        left:85%;
        width: 30%;
        opacity: 0.5;
        /* display: none; */
        transform: rotate(-30deg);
    }
    .community_star4{
        position: absolute;
        top:600%;
        left:85%;
        width: 30%;
        opacity: 0.25;
        /* display: none; */
        transform: rotate(-30deg);
    }
    .community_star5{
        position: absolute;
        top:800%;
        left:85%;
        width: 30%;
        opacity: 0.1;
        /* display: none; */
        transform: rotate(-30deg);
    }

    .cancer{
        padding-top: 2.5rem;
    }
    .community_pic{
        width: 50%;
        position: relative;
        left: 25%;
        padding-bottom: 1rem;
    }
    .community_pic1{
        width: 100%;
    }
}