@font-face {
    font-family: "GT-Haptik-Black-Trail";
    src: local("GT-Haptik-Black-Trail"), url(../../../font/GT-Haptik-Black-Trial.otf) format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@font-face{
    font-family: "GT-Walsheim-Pro-Regular-Trial";
    src: local("GT-Walsheim-Pro-Regular-Trial"), url(../../../font/GT-Walsheim-Pro-Regular-Trial.otf);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dfa_text{
    font-family:GT-Walsheim-Pro-Regular-Trial;
    color:white;
    margin: 0;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    font-size: 1.2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    background-color: #257a66;
}
.dfa_row{
    margin: 0;
    width: 100% !important;
    /* top:70%; */
    /* position: relative; */
    /* z-index: 1; */
}
.dfa_div{
    /* margin-left: 0; */
    display: flex;
    justify-content: flex-start;
    background-color: #257a66;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    margin-left: 0.2rem;
    position: relative;
    width:100%;
}

.dfa_div:nth-child(1)>div{
    width: 100%;
    margin-left: 1rem;
    margin-top: 1rem;
}
.dfa_div>img{
    max-width: 30%;
    margin: 0;
    flex:auto;
}

@media (max-width:767px){
    .dfa_text{
        font-size: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .dfa_row{
        top:0%;
        justify-content: center;
    }
    .dfa_row2{
        justify-content: center;
    }
    .dfa_div{
        display: flex;
        justify-content: flex-start;
        background-color: #257a66;
        margin-left: 0rem;
    }
    
    .dfa_div:nth-child(1)>div{
        width: 100%;
        margin-left: 0rem;
        margin-top: 1rem;
    }
    .dfa_div>img{
        max-width: 30%;
        margin: 0;
    }
}